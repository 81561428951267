import classNames from "classnames";
import Highlighter from "../../amrPipeline/common/Highlighter";
import { News } from "../../../types/news/News";
import { NewsCategory, newsCategoryTitles } from "../../../types/news/enums/NewsCategory";
import { NewsLabel, newsLabelTitles } from "../../../types/news/enums/NewsLabel";
import moment from "moment";
import { constants } from "../../../constants";
import { OnHoverTooltip } from "../../common";
import { NewsProvider, newsProviderLogos, newsProviderTitles } from "../../../types/news/enums/NewsProvider";

interface Props {
    selected: boolean;
    data: News;
    searchTerm: string;
    onClick: (referenceName: string) => void;
}

export const NewsNavItem = ({ selected, data, searchTerm, onClick }: Props) => {
    const {
        referenceName,
        title,
        category,
        label,
        provider,
        newsDateTime,
        published
    } = data;

    return (
        <div
            className={classNames(
                "sidebar-nav-list-item sidebar-nav-list-item-extended",
                { active: selected, "sidebar-nav-list-item-draft": !published }
            )}
            onClick={() => onClick(referenceName as string)}
        >
            <div className="sidebar-nav-list-item-content">
                <div className="flex-row">
                    <OnHoverTooltip overlay={title}>
                        <Highlighter
                            searchWords={[searchTerm]}
                            autoEscape={true}
                            textToHighlight={title}
                            className="title"
                        />
                    </OnHoverTooltip>
                    {!published && <div className="label-status label-status-draft">Draft</div>}
                </div>
                <div className="flex-row">
                    <div className="flex-row flex-item-left">
                        {!!category && category !== NewsCategory.None && (
                            <div className={classNames("label-status label-status-news-category", `label-status-${newsCategoryTitles[category].toLowerCase().replace(' ', '-')}`)}>
                                {newsCategoryTitles[category]}
                            </div>
                        )}
                        {label !== NewsLabel.None && (
                            <div className="news-label text-sm">
                                {newsLabelTitles[label]}
                            </div>
                        )}
                        {!!provider && provider !== NewsProvider.None && (
                            <div className="news-provider-clo">
                                <OnHoverTooltip overlay={`Provided by ${newsProviderTitles[provider]}`}>
                                    <div className={classNames({
                                        "news-provider-london-stock-exchange-group": NewsProvider.LondonStockExchangeGroup === provider,
                                        "news-provider-clo-img": NewsProvider.CLOResearchGroup === provider
                                    })}>
                                        <img src={newsProviderLogos[provider]} alt={newsProviderTitles[provider]} />
                                    </div>
                                </OnHoverTooltip>
                            </div>
                        )}
                    </div>
                    <div className="news-date flex-item-right text-sm text-regular">
                        {moment(newsDateTime).format(constants.dateFormat)}
                    </div>
                </div>
            </div>
        </div>
    );
};
