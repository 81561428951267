import { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { News, NewsSaveForm } from '../../../types/news/News';
import { FormField } from '../../amrPipeline/common/FormField';
import { InputField } from '../../forms';
import { SelectField } from '../../common/form-fields/SelectField';
import { DateField } from '../../common/form-fields/DateField';
import { EditorField } from '../../common/form-fields/EditorField';
import { Company } from '../../../types/amr-pipeline/models/Company';
import { useDispatch } from 'react-redux';
import { SelectSearchField } from '../../forms/SelectSearchField';
import { editNewsActions } from '../../../actions/edit-news.actions';
import { EditNewsValidationSchema } from './EditNewsValidationSchema';
import { useHistory } from 'react-router';
import { constants, routes } from '../../../constants';
import { EditNewsTabType } from '../../../types/news/enums/EditNewsTabType';
import { newsCategoryValues, newsLabelValues, newsProviderValues } from './constants';
import { FileUploader } from '../../amrPipeline/common/documents/FileUploader';
import { RouteLeavingGuard } from '../../routing';
import { FileList } from './FileList';

const resetOption = {
    value: '',
    text: constants.emptyPlaceholder,
    selected: false,
};

interface Props {
    data: NewsSaveForm;
    managers: Company[];
    arrangers: Company[];
    initialReferenceName?: string;
}

export const EditForm = ({ data, managers, arrangers, initialReferenceName }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isUploading, setIsUploading] = useState(false);

    const isNew = !data.referenceName && !data.title;

    const switchToViewMode = () => {
        const redirectReferenceName = data.referenceName || initialReferenceName;

        history.replace(
            redirectReferenceName
                ? routes.manageNewsUrl(redirectReferenceName, EditNewsTabType.view)
                : routes.manageNewsPage,
        );
    };

    const renderHeader = ({ values, dirty, setFieldValue, submitForm }: FormikProps<NewsSaveForm>) => {
        const isSaveDisabled = () => {
            if (isUploading) {
                return true;
            }

            return !values.published && !dirty;
        };

        const isPublishDisabled = () => {
            if (isUploading) {
                return true;
            }

            if (data.referenceName && !data.published) {
                return false;
            }

            return !dirty;
        };

        return (
            <div className="sub-header">
                <div className="sub-header-row type04 flex-row">
                    <h1>{isNew && !values.title ? 'Add News' : values.title}</h1>
                    <div className="flex-item-right flex-row">
                        <button className="btn btn-ghost btn-sm" onClick={switchToViewMode} type="button">
                            Cancel
                        </button>
                        <button
                            className="btn btn-ghost btn-sm"
                            disabled={isSaveDisabled()}
                            onClick={() => {
                                setFieldValue('published', false);
                                submitForm();
                            }}
                        >
                            {isNew ? 'Save Draft' : values.published ? 'Unpublish' : 'Save'}
                        </button>
                        <div className="vertical-divider" />
                        <button
                            className="btn btn-main btn-sm"
                            disabled={isPublishDisabled()}
                            type="submit"
                            onClick={() => setFieldValue('published', true)}
                        >
                            {values.published ? 'Save' : 'Publish'}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const handleSubmit = (newsForm: NewsSaveForm) => {
        dispatch(editNewsActions.createOrUpdateNews(newsForm as News));
    };

    return (
        <Formik
            initialValues={data}
            validationSchema={EditNewsValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {props => (
                <>
                    <RouteLeavingGuard
                        navigate={(pathname: string) => history.push(pathname)}
                        shouldBlockNavigation={() => !!props.dirty}
                    />
                    <Form className="container-flex">
                        {renderHeader(props)}
                        <div className="tabs-data tabs-data-padding">
                            <div className="news-info-box news-info-box-edit">
                                <FormField title="Title" required className="data-item-row-title-md">
                                    <InputField
                                        label=""
                                        markRequired={true}
                                        autoFocus
                                        className="form-control"
                                        name="title"
                                        placeholder="Enter Title"
                                        maxLength={512}
                                    />
                                </FormField>
                                <div className="column-row">
                                    <div className="column-row-col">
                                        <SelectField
                                            label="Category"
                                            name="category"
                                            values={newsCategoryValues}
                                            placeholder="Select Category"
                                            optionKeyProp="id"
                                            optionLabelProp="label"
                                            wrapperClassName="data-item-row-title-md"
                                        />
                                    </div>
                                    <div className="column-row-col">
                                        <SelectField
                                            label="Event/Label"
                                            name="label"
                                            values={newsLabelValues}
                                            placeholder="Select Event/Label"
                                            optionKeyProp="id"
                                            optionLabelProp="label"
                                            wrapperClassName="data-item-row-title-md"
                                        />
                                    </div>
                                </div>
                                <div className="column-row">
                                    <div className="column-row-col">
                                        <DateField
                                            dateFormat={constants.dateFormat}
                                            label="News Date"
                                            name="newsDateTime"
                                            required
                                            wrapperClassName="data-item-row-title-md"
                                        />
                                    </div>
                                    <div className="column-row-col">
                                        <SelectField
                                            label="News Provider"
                                            name="provider"
                                            values={newsProviderValues}
                                            placeholder="Select News Provider"
                                            optionKeyProp="id"
                                            optionLabelProp="label"
                                            wrapperClassName="data-item-row-title-md"
                                        />
                                    </div>
                                </div>
                                <FormField title="External Source Link" className="data-item-row-title-md">
                                    <InputField
                                        label=""
                                        markRequired={false}
                                        autoFocus
                                        className="form-control"
                                        name="externalSourceLink"
                                        placeholder="Add External Source Link"
                                    />
                                </FormField>
                                <FormField title="Deal Details" className="data-item-row-title-md">
                                    <InputField
                                        label=""
                                        markRequired={false}
                                        autoFocus
                                        className="form-control"
                                        name="transactionLink"
                                        placeholder="Add Transaction Link"
                                    />
                                </FormField>
                                <FormField title="Manager Profile" className="data-item-row-title-md">
                                    <SelectSearchField
                                        className="select-search-field"
                                        label=""
                                        name="cloManagerReferenceName"
                                        markRequired={false}
                                        placeholder="Select Manager Profile"
                                        options={[
                                            resetOption,
                                            ...managers.map(m => ({
                                                value: m.referenceName,
                                                text: m.legalName,
                                                selected: m.referenceName === props.values.cloManagerReferenceName,
                                            })),
                                        ]}
                                    />
                                </FormField>
                                <FormField title="Arranger" className="data-item-row-title-md">
                                    <SelectSearchField
                                        className="select-search-field"
                                        label=""
                                        name="arrangerReferenceName"
                                        markRequired={false}
                                        placeholder="Select Arranger"
                                        options={[
                                            resetOption,
                                            ...arrangers.map(a => ({
                                                value: a.referenceName,
                                                text: a.legalName,
                                                selected: a.referenceName === props.values.arrangerReferenceName,
                                            })),
                                        ]}
                                    />
                                </FormField>
                                <EditorField
                                    label="Description"
                                    name="description"
                                    required
                                    maxLength={10000}
                                    wrapperClassName="data-item-row-title-md data-item-row-full-height news-description-field"
                                />
                                <FormField title="Files" className="data-item-row-title-md data-item-row-full-height">
                                    <FileUploader
                                        referenceName={data.referenceName}
                                        documents={props.values.newsDocuments || []}
                                        onChange={files => props.setFieldValue('newsDocuments', files)}
                                        onUploading={setIsUploading}
                                    >
                                        {props => <FileList {...props} />}
                                    </FileUploader>
                                </FormField>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};
